<template>
  <div class="window-restyle-wrapper absolute inset-0 flex items-center justify-center" style="z-index: 1000">
    <div class="window window-restyle" style="filter: shadow;">
     <main class="window-background w-[400px]" style="flex-grow: 0;">
      <header class="window-title flex items-center">
				<span class="window-name font-normal flex-1" style="line-height: 1.3">
					{{ 'Сохранение проекта' }}
				</span>

        <a class="button button-transparent  top-0 right-0 m-[5px]" @click="close()">
          <Icon name="cross"></Icon>
        </a>
      </header>
      
      <div class="window-content overflow-y-auto space-y-[15px] max-h-[75vh]" style="flex: auto; flex-shrink: 1">
        <input class="form-input w-full pr-7.5" placeholder="Имя файла" v-model="filename" />
        <div class="flex justify-end">
          <a class="button button-transparent" @click="close()">Отмена</a>
          <!-- <button @click="close()">Отмена</button> -->
          <button
            type="submit"
            class="button button-positive w-[80px] h-[40px] clipshape-all text-white ml-[20px]"
            @click="save()"
          >Сохранить</button>
        </div>
        
      </div>

    </main>
  </div>
  </div>
</template>

<script setup>
import {ref, computed} from 'vue';
import {usePalleteStore} from "@/stores/pallete";
import {useEditorStore} from "@/stores/editor";
import {usePopUpsStore} from "@/stores/popups";
import Icon from "@/components/Icon"; // Replace with actual import if needed

const palleteStore = usePalleteStore()
const editorStore = useEditorStore()
const popUpsStore = usePopUpsStore()

let filename = ref(popUpsStore.saveProject.filename)
function close(){
  popUpsStore.saveProject.visible = false
}

function save(){
  // копия полностью
  const objects = JSON.parse(JSON.stringify(editorStore.objects))

  for (const object of objects) {
    if (object.position){
      object.left = object.position.left
      object.top = object.position.top
      delete object.position
    }
  }

  const jsonString = JSON.stringify(objects);

  const blob = new Blob([jsonString], {type: 'application/json'});

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;

  // // Окно запроса имени файла у пользователя
  // const suggestedFileName = 'проект';
  // const fileName = prompt("Введите имя файла:", suggestedFileName);

  // // Если имя файла не введено (пользователь нажал Отмена)
  // if (fileName === null) {
  //     URL.revokeObjectURL(url); // освобождаем URL, если пользователь отменил сохранение
  //     return;
  // }

  // Использование введенного пользователем имени файла
  a.download = filename.value+'.json';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  URL.revokeObjectURL(url);
  close();
}

</script>


<style scoped></style>